<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-upload v-if="item.type === 'upload2'" v-decorator="[item.name, { rules: item.rules }]" name="file"
          :data="uploadParams"
          :beforeUpload="beforeUpload">
          <a-button>
            <a-icon type="upload" /> 点击上传
          </a-button>
        </a-upload>
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择" showSearch allowClear :filterOption="filterOption" @focus="handleInput(index)" @search="handleSearch"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
           v-decorator="[item.name, { rules: item.rules }]"/>
        <l-editor v-else-if="item.type === 'textarea2'" name="package_description" :data="package_description"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'membership_card_id',
    label: '报读课程',
    type: 'select',
    rules: [],
    items: {
      data: 'classCategorys',
      label: 'filter_name',
      value: 'course_cate_id'
    }
  },
  {
    name: 'membership_card_studio',
    label: '所属校区',
    type: 'select',
    rules: [],
    items: {
      data: 'studios',
      label: 'filter_name',
      value: 'studio_id'
    }
  },
  {
    name: 'import_file',
    label: '导入文件',
    type: 'upload2',
    rules: []
  },
]
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '数据导入',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        classCategorys: [],
        studios: [],
      },
      
      imageUrl: '',
      imageRoot: url.imageRoot,
      uploadParams: {},
      imageKey: '',

      uploadUrl: url.uploadQiNiu, 
      file: null,
      
      package_description: '',
      editor: {},
      inpValue:'',
    }
  },

  async created() {
    this.visible = true
    await this.$nextTick()
    let inputClassVal = {"q":''}
    this.getClassCategory(inputClassVal)
    let inputStuVal = {"q":''}
    this.getStudio(inputStuVal)
    if (this.item) {
      let { item } = this
      let formFields = {}
      for (let d of formData) {
        formFields[d.name] = item[d.name]
      }
      this.imageUrl = item.package_cover
      this.package_description = item['package_description']
      this.form.setFieldsValue(formFields)
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleSearch(value){
      if(value){
        if(this.inpValue == 0){
          let inputClassVal = {"q":value}
          this.getClassCategory(inputClassVal)
        }else if(this.inpValue == 1){
          let inputStuVal = {"q":value}
          this.getStudio(inputStuVal)
        }
      }
    },
    handleInput(index){
      this.inpValue = index
    },
    async getClassCategory(value) {
      let res = await this.$store.dispatch('searchCourseTypeAction', {params:value})
      this.seleteItems.classCategorys = res.data
    },
    async getStudio(value) {
      let res = await this.$store.dispatch('searchBelongStudioAction', value)
      this.seleteItems.studios = res.data
    },
    changeEditor(key, value) {
      this.editor[key] = value
    },
    async handleOk() {
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          const formData = new FormData()
          if (!this.file) {
            return 
          }
          formData.append('course_cate_id', params.membership_card_id)
          formData.append('studio_id', params.membership_card_studio)
          formData.append('import_file', this.file)
          this.confirmLoading=true
          let res = await this.$store.dispatch('importImportUploadAction', { data: formData })
          if(res) {
            form.resetFields()
            this.parent.hideEditModal(1)
          }
        }
      } catch {
      }
			this.confirmLoading=false
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },
    beforeUpload(file) {
      this.file = file
      return false
    },
  }
}
</script>
