<template>
  <div>
    <div>
      <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-form-item v-if="canExcelimport">
              <a-button icon="import" @click="showUploadModal" type="primary">导入</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="search">
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="import_id"
          :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="action" slot-scope="text,record">
            <a href="javascript:;">撤回</a>
            <!-- <a-divider type="vertical" />
            <a href="javascript:;">详情</a> -->
          </template>
        </a-table>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    </div>
    <uploadModal v-if="isUploadModal"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index' },
  { title: '导入时间', dataIndex: 'created_at', key: 'created_at' },
  { title: '导入数量', dataIndex: 'import_count', key: 'import_count' },
  { title: '经办人', dataIndex: 'import_id', key: 'import_id' },
  { title: '导入状态', dataIndex: 'import_status', key: 'import_status' },
  { title: '操作', key: 'operation', width: 80, fixed: 'right', scopedSlots: { customRender: 'action' }},
]
    import uploadModal from './uploadModal'
    import authority from '@/common/mixins/authority'
    import tableMixins from '@/common/mixins/table'
    export default {
        name:'surplusCost',
        data() {
            return {
                columns,
                list: [],
                loading:false,
                isUploadModal:false,
                authType:['orginization','import'],
            }
        },
        components: {
          uploadModal,
        },
        mixins:[tableMixins,authority],
        methods: {
            getList() {
                
            },
            showUploadModal(){
              this.isUploadModal = true
            },
            hideUploadModal(){
              this.isUploadModal = false
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>