<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>校区管理</a-breadcrumb-item>
      <a-breadcrumb-item>数据导入</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <!-- <a-tab-pane key="1">
          <span slot="tab">科目数据<a-badge :count="0" /></span>
          <course v-if="activeKey==='1'"/>
        </a-tab-pane> -->
        <a-tab-pane key="2">
          <span slot="tab">学员数据<a-badge :count="0" /></span>
          <list v-if="activeKey==='2'"/>
        </a-tab-pane>
        <a-tab-pane key="3">
          <span slot="tab">班级数据<a-badge :count="0" /></span>
          <classes v-if="activeKey==='3'"/>
        </a-tab-pane>
        <a-tab-pane key="4">
          <span slot="tab">排课数据<a-badge :count="0" /></span>
          <arrangement v-if="activeKey==='4'"/>
        </a-tab-pane>
        <a-tab-pane key="5">
          <span slot="tab">学员剩余费用<a-badge :count="0" /></span>
          <cost v-if="activeKey==='5'"/>
        </a-tab-pane>
        <!-- <a-tab-pane key="7">
          <span slot="tab">学员信息<a-badge :count="0" /></span>
          <students v-if="activeKey==='7'"/>
        </a-tab-pane>       -->
      </a-tabs>
    </div>
  </div>
</template>

<script>
import list from './list'
import cost from './cost'
import course from './course'
import classes from './classes'
import surplusCost from './surplusCost'
import arrangement from './arrangement'
import students from './students'

export default {
  components: {
    list,
    cost,
    course,
    classes,
    students,
    surplusCost,
    arrangement,
  },
  data() {
    return {
      activeKey: '2'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

