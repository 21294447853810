<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="关闭" okText="确定" width="62.5%"
    @ok="handleOk" @cancel="handleCancel">
    <div class="table">
      <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
        :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </a-modal>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index' },
  // { title: '报读课程', dataIndex: 'course_cate_name', key: 'name' },
  { title: '所属校区', dataIndex: 'studio_name', key: 'age'},
  { title: '课程类型', dataIndex: 'course_cate_name', key: '1' },
  // { title: '学员卡号', dataIndex: 'studio_id', key: '2' },
  { title: '学员名称', dataIndex: 'student_name', key: '3' },
  { title: '手机号码', dataIndex: 'student_cellphone', key: '4' },
  { title: '开始时间', dataIndex: 'start_date', key: '5' },
  { title: '结束时间', dataIndex: 'end_date', key: '6' },
  { title: '联系人名称', dataIndex: 'contact_name', key: '7' },
  { title: '联系人电话', dataIndex: 'contact_phone', key: '8' },
  { title: '联系人关系', dataIndex: 'contact_type', key: '9' },
  { title: '购买价格', dataIndex: 'price', key: '10' },
  { title: '有效课时数', dataIndex: 'left_times', key: '11' },
  { title: '有效赠课数', dataIndex: 'left_gift_times', key: '12' },
  { title: '购买课时总数', dataIndex: 'total_times', key: '13' },
  { title: '状态', dataIndex: 'status', key: '14' },
  { title: '导入时间', dataIndex: 'created_at', key: '15' },
]

import url from '@/utils/URL'
import createFilePath from '@/utils/tools'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      ModalTitle: '导入详情',
      visible: false,
      confirmLoading: false,
      seleteItems: {
        classCategorys: [],
        studios: [],
      },
    }
  },
  mixins:[tableMixins],
  async created() {
    this.visible = true
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getClassCategory() {
      let res = await this.$store.dispatch('searchCourseAction', {})
      this.seleteItems.classCategorys = res.data
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.seleteItems.studios = res.data
    },
    async getList() {
      let res = await this.$store.dispatch('importImportImportLogAction', {data: {import_id: this.item.import_id}})
      this.list = res.items
      this.pageParams = res._meta
    },
    changeEditor(key, value) {
      this.editor[key] = value
    },
    async handleOk() {
      this.confirmLoading=true;
      this.parent.hideListModal(0)
    },
    handleCancel() {
      this.parent.hideListModal(0)
    },
  }
}
</script>
