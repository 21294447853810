<template>
  <div>
    <div>
      <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-form-item v-if="canExcelimport">
              <a-button icon="import" @click="showUploadModal" type="primary">导入</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="search">
          <a-form layout='inline' @submit="searchList">
            <a-form-item>
              <a-range-picker style="width:240px" allowClear :ranges="rangesData" v-model="searchData.created_at" :placeholder="['开始时间', '结束时间']"/>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" html-type="submit" :loading="loading" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="import_id"
          :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="name" slot-scope="text,record">
                <a @click="toPreview(record)" href="javascript:;">{{record.title}}</a>
          </template>
          <template slot="action" slot-scope="text,record">
            <!-- <a v-if="canExcelimport" href="javascript:;" @click="checkImportItem(record)">导入</a> -->
            <!-- <a-divider v-if="canExcelimport" type="vertical" /> -->
            <a href="javascript:;" @click="deleteImportItem(record)">撤回</a>
            <!-- <a-divider type="vertical" />
            <a href="javascript:;" @click="showListModal(record)">详情</a> -->
          </template>
        </a-table>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    </div>
    <uploadModal v-if="isUploadModal"/>
    <listModal v-if="listVisible" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index' },
  { title: '导入文件名', dataIndex: 'title', key: 'name',scopedSlots: { customRender: 'name' } },
  { title: '导入数量', dataIndex: 'import_count', key: '1' },
  { title: 'IP地址', dataIndex: 'ip', key: '3' },
  { title: '经办人', dataIndex: 'employee_id', key: '4' },
  { title: '导入时间', dataIndex: 'created_at', key: '5' },
  { title: '操作', key: 'operation', width: 80, fixed: 'right', scopedSlots: { customRender: 'action' }},
]
    import ranges from "@/common/mixins/ranges"
    import moment from 'moment' 
    import uploadModal from './uploadModal'
    import listModal from '../list/listModal'
    import authority from '@/common/mixins/authority'
    import tableMixins from '@/common/mixins/table'
    export default {
        name:'classes',
        data() {
            return {
                columns,
                list: [],
                searchData:{},
                loading:false,
                listVisible:false,
                isUploadModal:false,
                authType:['orginization','import'],
            }
        },
        components: {
          listModal,
          uploadModal,
        },
        mixins:[ranges ,tableMixins,authority],
        methods: {
            async getList() {
              if(!this.canIndex){
                  this.$message.warning('您暂无权限!!')
                  return false
              }
              this.loading = true
              this.searchParams.search.import_type = 'classes'

              var searchData = this.searchData
              for (let k in searchData) {
                if (k === 'created_at') {
                  this.searchParams.search['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
                  this.searchParams.search['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
                } else {
                  this.searchParams.search[k] = searchData[k]
                }
              }
              let res = await this.$store.dispatch('importImportAction', { data: this.searchParams })
              this.list = res.items
              this.pageParams = res._meta
              this.loading = false
            },
            showUploadModal(){
              this.isUploadModal = true
            },
            hideUploadModal(){
              this.isUploadModal = false
            },
            showListModal(item) {
              this.modalData = item
              this.listVisible = true
            },
            hideListModal(type) {
              if (type === 1) {
                this.getList()
              }
              this.listVisible = false
            },
            checkImportItem(item) {
              let that = this
              this.$confirm({
                title: `您确定要导入吗？`,
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                  return new Promise(async (resolve, reject) => {
                    let res = await that.$store.dispatch('importImportCheckImportAction', { data: { import_id: item.import_id } })
                    if (res) {
                      that.getList()
                      resolve(res)
                    }
                  }).catch(error => console.log(error))
                }
              })
            },
            deleteImportItem(item) {
              let that = this
              this.$confirm({
                title: `您确定要撤回导入吗？`,
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                  return new Promise(async (resolve, reject) => {
                    let res = await that.$store.dispatch('importImportDeleteImportAction', { data: { import_id: item.import_id } })
                    if (res) {
                      that.getList()
                      resolve(res)
                    }
                  }).catch(error => console.log(error))
                }
              })
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>